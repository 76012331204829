import React from "react";
import {graphql} from 'gatsby';
import FilterPage from "../components/FilterPage";
import Seo from "../components/Seo";


export default function Support(props) {
  return (
    <FilterPage
      data={props.data}
      location={props.location}
      hideSort={true}
      hideSearch={true}
      title={'Support'}
      description={'<a href="https://cleancatalog.notion.site/Clean-Catalog-Support-Docs-d1f15096e5c849bfb9d2e0acfc868f0e">Our full support docs are available here.</a><br /><br /> For support with your Clean Catalog site, you can email <a href="mailto:support@cleancatalog.com">support@cleancatalog.com</a> or contact your account representative directly.'}
    />
  )
}

export function Head(props) {
  return (
    <Seo
      title={'Support'}
      description={'For support with your Clean Catalog site, you can email support@cleancatalog.com or contact your account representative directly.'}
      canonical={props.location.pathname}
    />
  )
}


export const pageQuery = graphql`
  query
  {
    allMarkdownRemark(filter
    :
    {
      frontmatter: {
        type: {
          eq: "support"
        }
      }
    }
    )
    {
      edges
      {
        node
        {
          fields
          {
            slug
          }
          frontmatter
          {
            date
            title
            tags
            path
            logo
            featured
            vimeoid
          }
          excerpt
        }
      }
      group(field
      :
      frontmatter___tags
      )
      {
        fieldValue
      }
    }
  }
`

